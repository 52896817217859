<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import router from "@/router";
import { HTTP } from "@/main-source";
import Swal from "sweetalert2";
/**
 * Starter page component
 */
export default {
  page: {
    meta: [{ name: "List of investors" }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      xhrRequest: true,
      list: [],
      includePassive: false,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    addNew: function () {
      let routeName = null;

      switch (this.$route.name) {
        case "investors":
          routeName = "new-investor";
          break;
        case "companies":
          routeName = "new-company";
          break;
        case "events":
          routeName = "new-event";
          break;

        default:
          break;
      }
      router.push({
        name: routeName,
      });
    },

    getData: function () {
      this.xhrRequest = true;
      this.list = [];
      let uri = "firms";

      if (this.$route.name == "investors") {
        uri += "?isInvestor=true";
      }

      if (this.$route.name == "events") {
        uri = "events";
      }

      if (this.includePassive) {
        uri += this.$route.name == "investors" ? "&" : "?";
        uri += "includePassive=true";
      }

      HTTP.get(uri)
        .then((result) => {
          this.list = result.data.rows;
          this.xhrRequest = false;
        })
        .catch(() => {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Error while getting your data",
            showConfirmButton: false,
            timer: 1500,
          });
          this.xhrRequest = false;
        });
    },
  },
  watch: {
    "$route.name": function () {
      this.getData();
    },
    includePassive: function () {
      this.getData();
    },
  },
  computed: {
    pageTitle: function () {
      let pageTitle = null;

      switch (this.$route.name) {
        case "investors":
          pageTitle = "Investors";
          break;
        case "companies":
          pageTitle = "Companies";
          break;
        case "events":
          pageTitle = "Events";
          break;

        default:
          break;
      }
      return pageTitle;
    },
    getPath: function () {
      let routeName = null;

      switch (this.$route.name) {
        case "investors":
          routeName = "investor-detail";
          break;
        case "companies":
          routeName = "company-detail";
          break;
        case "events":
          routeName = "event-detail";
          break;

        default:
          break;
      }
      return routeName;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="pageTitle" />

    <div class="process-row">
      <button type="button" class="btn btn-success" @click="addNew">
        + Add New
      </button>
    </div>

    <div class="row">
      <div class="col-12">
        <div
          class="custom-switch switch-primary mt-n2 mb-2"
          v-if="$route.name != 'events'"
        >
          <input
            type="checkbox"
            class="custom-control-input"
            id="includePassive"
            :cheched="includePassive"
            v-model="includePassive"
          />
          <label for="includePassive" class="custom-control-label">
            Include Passive Records
          </label>
        </div>
      </div>
    </div>

    <div class="row" :class="{ waiting: xhrRequest }">
      <div class="spinner-border text-success" role="status" v-if="xhrRequest">
        <span class="sr-only">Loading...</span>
      </div>
      <div class="d-flex firm-lists w-100" v-if="xhrRequest == false">
        <div class="col-lg-6 col-xl-3" v-for="(item, key) in list" :key="key">
          <router-link :to="{ name: getPath, params: { id: item.id } }">
            <b-card
              :img-src="
                item.profileImg
                  ? $apiDomain + item.profileImg
                  : require('@/assets/images/no-image.jpg')
              "
              img-alt="Card image"
              img-top
            >
              <b-card-text class="text-center"> {{ item.name }} </b-card-text>
              <div class="row" v-if="$route.name == 'events'">
                <div class="col">
                  <i class="ri-calendar-2-line"></i>
                  {{ new Date(item.date).toLocaleDateString("en") }}
                </div>
                <div class="col">
                  <i class="ri-time-line"></i>
                  {{ new Date(item.date).toLocaleTimeString("en") }}
                </div>
              </div>
            </b-card>
          </router-link>
        </div>
      </div>
    </div>
  </Layout>
</template>